<template>
    <div class="container py-5">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/dashboard">Admin Dashboard</router-link></li>
                <li class="breadcrumb-item"><router-link to="/admincollaborators">Collaborators</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Collaborator</li>
            </ol>
        </nav>
        <form @submit.prevent="handleSubmit">
            <div class="row justify-content-between">
                <div class="col-12">
                    <h1>{{addDecorator}}Collaborator</h1>
                    <small class="text-muted">Id: {{collaborator.id}}</small>
                </div>
            </div>

            <div class="mb-3 row">
                <label for="name" class="col-sm-2 col-form-label">Name:</label>
                <div class="col-sm-10">
                    <input v-model="collaborator.name" type="text" class="form-control" id="name" placeholder="name">
                </div>
            </div>
            <div class="mb-3 row">
                <label for="dept" class="col-sm-2 col-form-label">Department:</label>
                <div class="col-sm-10">
                    <input v-model="collaborator.department" type="text" class="form-control" id="dept" placeholder="dept">
                </div>
            </div>
            <div class="mb-3 row">
                <label for="org" class="col-sm-2 col-form-label">Organization:</label>
                <div class="col-sm-10">
                    <input v-model="collaborator.organization" type="text" class="form-control" id="org" placeholder="org">
                </div>
            </div>

            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <div v-if="loading" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <button v-else class="btn btn-primary">Save</button>
            </div>
        </form>
    </div>
</template>
<script>
    import axios from "axios";
    import { authHeader } from '@/services/authHeader.js';
    import { toastOptions } from '@/helpers/toastOptions.js';

    export default {
        name: 'AdminCollaborator',
        data() {
            return {
                loading: false,
                addDecorator : '',
                collaborator: {
                    id: 0,
                    name: '',
                    department: '',
                    organization: ''
                }
            }
        },
        methods: {
            async getData() {
                if (this.$route.query.id) {
                    this.addDecorator = '';
                    await axios.get(process.env.VUE_APP_URL + `api/collaborators/${this.$route.query.id}`)
                        .then(response => {
                            this.collaborator = response.data;
                            this.addDecorator = 'Edit ';
                        })
                        .catch(error => {
                            this.$toast(error.message, toastOptions.error);
                        })
                }
                else {
                    this.addDecorator = "Add ";
                    this.collaborator = {
                        id: 0,
                        name: '',
                        department: '',
                        organization: ''
                    }
                }
            },
            async handleSubmit() {
                this.loading = true;
                const options = {
                    headers: { ...authHeader(), 'Content-Type': 'application/json' }
                }
                if (this.collaborator.id) {
                    await axios.put(process.env.VUE_APP_URL + `api/collaborators/${this.collaborator.id}`, this.collaborator, options)
                        .then(() => {
                            this.loading = false;
                            this.$toast("Update successfull", toastOptions.success);
                        })
                        .catch(error => {
                            this.$toast(error.message, toastOptions.error);
                        });
                }
                else {
                    await axios.post(process.env.VUE_APP_URL + 'api/collaborators', this.collaborator, options)
                        .then(() => {
                            this.loading = false;
                            this.$toast("Successfully added new item", toastOptions.success);
                            this.$router.push('admincollaborators');
                        })
                        .catch(error => {
                            this.$toast(error.message, toastOptions.error);
                        });
                }
            }
        },
        watch: {
            '$route.query.id': {
                handler: function () {
                    if (this.$route.query.id) {
                        this.getData();
                    }
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>
